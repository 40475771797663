import React from "react";
import { View, Text } from "react-native";
import * as Sentry from "sentry-expo";
import AppNavigator from "./src/AppNavigator";
import { Context as PlanContext } from "./src/context/PlanContext";
import { Provider as PlanProvider } from "./src/context/PlanContext";
import { Provider as CodeProvider } from "./src/context/CodeContext";
import { Provider as LocalProvider } from "./src/context/LocalContext";
//import LoadingView from "./src/screens/Home/LoadingView";
import "@expo/match-media";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { GlobalDebug } from "./src/functions/removeConsoles";

// Sentry.init({
//     dsn: "https://383cf82a8c314563816392fa5931938b@o373137.ingest.sentry.io/5382005",
//     enableInExpoDevelopment: true,
//     debug: true,
//   });

import "./fonts.css";

function App() {
  React.useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  });

  return (
    <LocalProvider>
      <CodeProvider>
        <PlanProvider>
          <ToastContainer />
          <AppNavigator />
        </PlanProvider>
      </CodeProvider>
    </LocalProvider>
  );
}

export default App;
