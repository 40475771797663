import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Progress } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "../StepComponent/Connect/devices.min.css";

const PhoneDisplay = ({ content }) => {
  const {
    selectTab,
    state: { plan, gradients },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  const headerComponent = ({ index, title }) => (
    <View key={`header-${index}`}>
      <Text style={{ color: plan.company.darkColor }} small b>
        {title}
      </Text>
      <View style={{ marginVertical: 10 }} />
    </View>
  );

  const progressComponent = ({ index, title, value, max }) => (
    <View key={`progress-${index}`}>
      <Text style={{ color: "grey" }} small font="13px">
        {title}
      </Text>
      <View style={{ marginVertical: 5 }} />
      <Progress value={value} max={max} />
      <View style={{ marginVertical: 10 }} />
    </View>
  );

  const gradientComponent = ({ index, key, value, unit }) => (
    <View
      key={`gradient-${index}`}
      style={{
        background: gradients[content.order],
        width: "100%",
        marginBottom: 20,
        padding: 20,
        borderRadius: 5,
        alignItems: "flex-end",
        marginVertical: 10,
      }}
    >
      <Text
        style={{
          color: "white",
          textAlign: "end",
          marginBottom: -30,
        }}
        b
      >
        {key}
      </Text>
      <View style={{ marginVertical: 15 }} />
      <Text
        style={{
          color: "white",
          textAlign: "end",
          marginBottom: -10,
        }}
        h2
      >
        {value}
        <Text span font="16px">
          {unit}
        </Text>
      </Text>
    </View>
  );

  const checklistComponent = ({ index, data }) => (
    <View key={`check-${index}`}>
      {data.map((elem) => {
        return (
          <View
            key={elem.key}
            style={{
              marginVertical: 5,
              backgroundColor: "#f2f2f2",
              borderRadius: 5,
              padding: 15,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text small>{elem.key}</Text>

            {elem.type == null || elem.type == "bool" ? (
              <>
                {elem.value == true ? (
                  <Ionicons
                    name="checkmark-circle"
                    size={24}
                    color={plan.company.darkColor}
                  />
                ) : (
                  <View
                    style={{
                      height: 20,
                      width: 20,
                      borderRadius: 20,
                      borderWidth: 2,
                      borderColor: plan.company.darkColor,
                      marginRight: 3,
                    }}
                  />
                )}
              </>
            ) : elem.type == "num" ? (
              <Text style={{ color: plan.company.lightColor }} b>
                {eval(elem.value)}
              </Text>
            ) : null}
          </View>
        );
      })}
    </View>
  );

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <View style={{ height: "100%", width: "100%", alignSelf: "center" }}>
            <ImageBackground
              source={{
                uri: content.headerImage,
              }}
              resizeMode="cover"
              style={{
                height: 150,
                width: "100%",
              }}
            >
              <View
                style={{
                  backgroundColor: plan.company.darkColor,
                  opacity: 0.7,
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 5,
                }}
              />
              <View
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 6,
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                  paddingHorizontal: 25,
                  paddingVertical: 15,
                }}
              >
                <Text style={{ color: plan.company.lightColor }} small b>
                  {eval(content.subHeader)}
                </Text>
                <Text
                  style={{
                    color: "white",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                  h3
                >
                  {eval(content.header)}
                </Text>
              </View>
            </ImageBackground>
            <ScrollView
              contentContainerStyle={{
                width: "100%",
                paddingHorizontal: 25,
                paddingTop: 25,
                //marginBottom: 200,
              }}
              showsVerticalScrollIndicator={false}
            >
              <View>
                {content.data.map((elem, index) => {
                  switch (elem.component) {
                    case "header":
                      return headerComponent({
                        index,
                        title: eval(elem.title),
                      });
                    case "progress":
                      return progressComponent({
                        index,
                        title: eval(elem.title),
                        value: eval(elem.value),
                        max: eval(elem.max),
                      });
                    case "gradient-stat":
                      return gradientComponent({
                        index,
                        key: eval(elem.key),
                        value: eval(elem.value),
                        unit: eval(elem.unit),
                      });
                    case "checklist":
                      return checklistComponent({
                        index,
                        data: elem.data,
                      });
                  }
                })}
              </View>
            </ScrollView>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default PhoneDisplay;
