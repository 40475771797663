import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View, ScrollView, ActivityIndicator } from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Dot } from "@geist-ui/react";
import { Ionicons } from "@expo/vector-icons";
import _ from "lodash";

import colors from "@res/colors";
import fonts from "@res/fonts";

const CodeFields = ({
  onExecute,
  isExecuting,
  curlRequest,
  requestFields,
  changedVars,
  executeTrigger,
}) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);
  const [fieldRequest, setFieldRequest] = useState(curlRequest);
  const [vars, setVars] = useState(requestFields);

  useEffect(() => {
    const changePreloads = () => {
      const varsClone = _.cloneDeep(vars);
      let result = fieldRequest;
      changedVars.forEach((elem) => {
        const varIndex = varsClone.findIndex(
          (subElem) => `${subElem.key}` == `${elem.key}`
        );

        result = fieldRequest.replace(
          `"${varsClone[varIndex].value}"`,
          `"${elem.value}"`
        );

        varsClone[varIndex].value = `${elem.value}`;
      });
      setFieldRequest(result);
      setVars([...varsClone]);
    };
    if (changedVars.length > 0) changePreloads();
  }, [changedVars]);

  useEffect(() => {
    if (executeTrigger == true) {
      onExecuteWithStorage(fieldRequest);
    }
  }, [executeTrigger]);

  const onExecuteWithStorage = (req) => {
    let clonedReq = req;
    vars.forEach((v) => {
      const parsed = parseInt(v.value);
      console.log(parsed);
      if (isNaN(parsed) == false && v.type == 2) {
        console.log("Num present");
        clonedReq = clonedReq.replace(`"${v.value}"`, parsed);
      }
    });
    console.log(clonedReq);
    onExecute(clonedReq);
  };

  const onFieldChange = (event, field) => {
    const newValue = `${event.target.value}`;

    const varCopy = [...vars];
    const currVar = varCopy.findIndex((elem) => `${elem.key}` == `${field}`);

    // Set cURL req
    const result = fieldRequest.replace(
      `"${vars[currVar].value}"`,
      `"${newValue}"`
    );
    setFieldRequest(result);

    // Set new values in vars state
    varCopy[currVar].value = newValue;
    setVars([...varCopy]);
  };

  return (
    <View style={{ alignSelf: "center", width: "100%" }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text font="20px" style={{ marginRight: 15 }}>
          Parameters
        </Text>
        <Text style={{ color: "grey" }} small>
          {vars.length} Fields
        </Text>
      </View>

      <View
        style={{
          borderWidth: 1,
          borderColor: "#eaeaea",
          borderRadius: 5,
        }}
      >
        <ScrollView
          contentContainerStyle={{
            //maxHeight: 200,
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            paddingHorizontal: 15,
            paddingTop: 0,
            paddingBottom: 10,
          }}
        >
          {vars.map((item, index) => {
            const preloadValue = _.get(localDatabase, item.preloadKey);
            const preloadDesc = _.get(localDatabase, item.preloadDesc);

            return (
              <View
                key={`${item.key}`}
                style={{ width: "48%", minWidth: 100, paddingHorizontal: 2 }}
              >
                <View style={{ width: "100%" }}>
                  {item.preloadKey ? (
                    <View
                      style={{ flexDirection: "row", alignItems: "center" }}
                    >
                      <View
                        style={{
                          height: 8,
                          width: 8,
                          borderRadius: 15,
                          backgroundColor:
                            preloadDesc != null
                              ? plan.company.darkColor
                              : "#f4a522",
                          marginRight: 8,
                        }}
                      />

                      <Text style={{ color: "grey" }} font="11px">
                        Prefilled{" "}
                        {preloadDesc != null ? preloadDesc : item.desc}
                      </Text>
                    </View>
                  ) : (
                    <View>
                      <Text style={{ color: "grey" }} font="11px">
                        {item.desc}
                      </Text>
                    </View>
                  )}

                  {item.preloadKey == null ? (
                    <Input
                      width={"100%"}
                      label={item.key}
                      placeholder={item.value}
                      onChange={(event) => onFieldChange(event, item.key)}
                    />
                  ) : (
                    <Input
                      width={"100%"}
                      label={item.key}
                      placeholder={item.value}
                      value={
                        preloadValue == null ? "Not Completed" : preloadValue
                      }
                      type={preloadValue == null ? "warning" : "secondary"}
                      onChange={(event) => onFieldChange(event, item.key)}
                    />
                  )}

                  <View style={{ marginVertical: 2.5 }} />
                </View>
              </View>
            );
          })}
        </ScrollView>
      </View>

      <View style={{ marginVertical: 10 }} />
      <Button
        style={{
          padding: 0,
          alignSelf: "flex-start",
          backgroundColor: plan.company.darkColor,
          borderColor: plan.company.darkColor,
        }}
        auto
        type="secondary-light"
        onClick={() => onExecuteWithStorage(fieldRequest)}
        loading={isExecuting}
      >
        <View
          style={{
            width: 200,
            flexDirection: "row",
            justifyContent: "space-between",
            height: "100%",
            alignItems: "center",
            paddingHorizontal: 15,
          }}
        >
          <Text style={{ color: plan.company.lightColor }} b>
            Execute Request
          </Text>
          <Ionicons
            name="arrow-forward-sharp"
            size={20}
            color={plan.company.lightColor}
          />
        </View>
      </Button>
    </View>
  );
};

const styles = StyleSheet.create({});

export default CodeFields;
