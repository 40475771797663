import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card, Progress } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "../Connect/devices.min.css";

const ScreenshotOverlay = ({ order, stepContent }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);

  const phoneElem = (content) => {
    return (
      <div className="marvel-device iphone-x" style={{ "--size-divisor": 1.2 }}>
        <div className="notch">
          <div className="camera"></div>
          <div className="speaker"></div>
        </div>
        <div className="top-bar"></div>
        <div className="sleep"></div>
        <div className="bottom-bar"></div>
        <div className="volume"></div>
        <div className="overflow">
          <div className="shadow shadow--tr"></div>
          <div className="shadow shadow--tl"></div>
          <div className="shadow shadow--br"></div>
          <div className="shadow shadow--bl"></div>
        </div>
        <div className="inner-shadow"></div>
        <div className="screen">{content}</div>
      </div>
    );
  };

  return (
    <View
      style={{
        width: "100",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <View style={{ alignSelf: "center" }}>
        {phoneElem(
          <View style={{ height: "100%", width: "100%", alignSelf: "center" }}>
            <ImageBackground
              source={{
                uri: stepContent.content.backgroundImage,
              }}
              resizeMode="cover"
              style={{
                height: "100%",
                width: "100%",
                paddingVertical: 50,
                paddingHorizontal: 25,
              }}
            >
              <View
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  borderColor: plan.company.lightColor,
                  borderWidth: 2,
                  borderRadius: 8,
                  flexDirection: "row",
                  padding: 15,
                }}
              >
                <View
                  style={{
                    backgroundColor: "#e6e6e6",
                    borderRadius: 5,
                    paddingHorizontal: 10,
                    paddingVertical: 5,
                    alignSelf: "flex-start",
                    marginRight: 10,
                    borderColor: plan.company.lightColor,
                    borderWidth: 2,
                  }}
                >
                  <Ionicons
                    name="barbell-sharp"
                    size={24}
                    color={plan.company.lightColor}
                  />
                </View>
                <View style={{ width: "70%" }}>
                  <Text h6>Course Recommendation</Text>
                  <Text style={{ color: "grey" }} small font="10px">
                    Your sleep quality is declining. Try out the Before Bed
                    Habits course!
                  </Text>
                </View>
              </View>
            </ImageBackground>
          </View>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default ScreenshotOverlay;
