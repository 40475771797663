import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";

const MultiAction = ({ content }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);

  const cardHeaderComp = (icon, header, subHeader) => (
    <View style={{ marginBottom: 25 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: -10,
        }}
      >
        <View
          style={{
            height: 20,
            width: 20,
            borderRadius: 50,
            backgroundColor: "black",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
            marginTop: 6,
          }}
        >
          <Ionicons name={icon} size={11} color={plan.company.lightColor} />
        </View>
        <Text
          style={{
            fontFamily:
              plan.company.headingFont != null
                ? plan.company.headingFont
                : null,
          }}
          h5
        >
          {header}
        </Text>
      </View>
      <Text style={{ color: "grey" }} font="11px">
        {subHeader}
      </Text>
    </View>
  );

  return (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: plan.company.darkColor, borderTopWidth: 3 },
      ]}
    >
      <View style={{ width: 300, justifyContent: "space-between" }}>
        <Card>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <Image
              source={{
                uri: eval(content.data[0].entityImage),
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 80,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
                borderWidth: 0.5,
                borderColor: "lightgrey",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              {eval(content.data[0].entitySubHeader)}
            </Text>
            <Text
              style={{
                marginBottom: 5,
                textAlign: "center",
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
                color: plan.company.darkColor,
              }}
              h4
            >
              {eval(content.data[0].entityHeader)}
            </Text>
            <Text style={{ color: "grey", marginBottom: 10 }} small>
              {eval(content.data[0].entityBody)}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: plan.company.darkColor,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons
              name="ios-checkmark-circle"
              size={24}
              color={plan.company.lightColor}
            />
            <Text style={{ color: plan.company.lightColor }} small b>
              {eval(content.data[0].successLabel)}
            </Text>
            <View />
          </TouchableOpacity>
        </Card>

        <View
          style={{
            alignSelf: "center",
            marginVertical: 10,
            width: 0,
            borderLeftWidth: 1,
            borderLeftColor: "grey",
            borderStyle: "dashed",
            height: 75,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: plan.company.darkColor,
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 6,
              paddingHorizontal: 15,
              alignSelf: "center",
              borderRadius: 50,
            }}
          >
            <Text style={{ color: "white" }} small b>
              {eval(content.connectingString)}
            </Text>
          </View>
        </View>

        <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
          {content.data[1].header != null
            ? cardHeaderComp(
                content.data[1].headerIcon,
                eval(content.data[1].header),
                eval(content.data[1].subHeader)
              )
            : null}
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: eval(content.data[1].entityImage),
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              {eval(content.data[1].entitySubHeader)}
            </Text>
            <Text
              style={{
                marginBottom: -10,
                textAlign: "center",
                fontFamily:
                  plan.company.headingFont != null
                    ? plan.company.headingFont
                    : null,
              }}
              h4
            >
              {eval(content.data[1].entityHeader)}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: plan.company.darkColor,
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons
              name="ios-checkmark-circle"
              size={24}
              color={plan.company.lightColor}
            />
            <Text style={{ color: plan.company.lightColor }} small b>
              {eval(content.data[1].successLabel)}
            </Text>
            <View />
          </TouchableOpacity>
        </Card>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,
    elevation: 9,
  },
});

export default MultiAction;
