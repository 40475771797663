import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Dot } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer } from "recharts";

const PieGraph = ({ content, height }) => {
  const {
    state: { plan },
  } = useContext(PlanContext);
  const {
    state: { localDatabase },
  } = useContext(LocalContext);

  return (
    <View
      style={[
        styles.databaseView,
        {
          borderTopColor: plan.company.darkColor,
          borderTopWidth: 3,
          width: "100%",
          height: height != null ? height : "100%",
        },
      ]}
    >
      <View style={{ width: "100%", justifyContent: "space-between" }}>
        <View
          style={{
            alignSelf: "center",
            alignItems: "center",
            marginBottom: 25,
            width: "100%",
          }}
        >
          <View
            style={{
              height: 50,
              width: 50,
              borderRadius: 50,
              backgroundColor: plan.company.darkColor,
              marginBottom: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ionicons
              name={content.headerIcon}
              size={24}
              color={plan.company.lightColor}
            />
          </View>
          <Text
            style={{
              marginBottom: -10,
              textAlign: "center",
              color: plan.company.darkColor,
              fontFamily:
                plan.company.headingFont != null
                  ? plan.company.headingFont
                  : null,
            }}
            h4
          >
            {eval(content.header)}
          </Text>
          <Text style={{ color: "grey", textAlign: "center" }}>
            {eval(content.subHeader)}
          </Text>
        </View>

        <ResponsiveContainer
          width="99%"
          height={height != null ? 0.4 * height : 300}
        >
          <PieChart height={350}>
            <Pie
              dataKey="value"
              isAnimationActive={true}
              data={content.data}
              cx="50%"
              cy="50%"
              outerRadius={80}
              fill={plan.company.darkColor}
              label
            />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>

        <View style={{ marginVertical: 15 }} />

        <TouchableOpacity
          style={{
            backgroundColor: plan.company.darkColor,
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
          }}
        >
          <Ionicons
            name="ios-checkmark-circle"
            size={24}
            color={plan.company.lightColor}
          />
          <Text style={{ color: plan.company.lightColor }} small b>
            {eval(content.successLabel)}
          </Text>
          <View />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,
    elevation: 9,
  },
});

export default React.memo(PieGraph);
